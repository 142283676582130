import * as React from "react"
import './PlayStation.css';
import Creative from "../../components/Creative"
import backgroundPlayStation from "./images/playstation_background.jpg"
import playstationControler from "./images/playstation_controler.png"
import playstationPackshot from "./images/playstation_packshot.png"
import backImage from "./images/playstation_backImage.png"
import video from "./images/playstation_video.mp4"
import SimpleSidebar from "../../components/layout"


const PlayStation = () => (
  <SimpleSidebar>
    <Creative
      className="playstation"
      video={video}
      background={backgroundPlayStation}
      backImage={backImage}
      heading="Blood & Truth"
      tags={["interaction", "scratch", "video",]}
      tagBackground="#034da2"
      tagColor="#ffffff"
      headingColor="#034da2"
      variant="rightBottom"

    >
      <img src={playstationPackshot} className="playstation_image packshot"/>
      <img src={playstationControler} className="playstation_image controler"/>
    </Creative>
  </SimpleSidebar>
)

export default PlayStation
